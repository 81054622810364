import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo1 from "../../Media/cricketlogo.png";
import "./Registration.css";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { API } from "../../API/API";
import { toast } from "react-toastify";
import Select from "react-select";

export default function Registration() {
  let history = useNavigate();
  const [checkbox, setcheckbox] = useState(false);
  const [SponsorId, setSponsorId] = useState(null);
  const [SponsorName, setSponsorName] = useState(null);
  const [f_name, setf_name] = useState(null);
  const [Password, setPassword] = useState(null);
  const [ConfirmPassword, setConfirmPassword] = useState(null);
  const [MobileNo, setMobileNo] = useState(null);
  const [EmailId, setEmailId] = useState(null);
  const [EmailIdError, setEmailIdError] = useState(null);
  const [countries, setCountries] = useState([]);
  const [country, setSelectedCountry] = useState("");
  const [PhoneCode, setPhonecode] = useState("");
  const [CountryId, setCountryId] = useState("");
  const [CountryName, setCountryName] = useState("");
  const [Profileotpvalid, setProfileOTPValid] = useState("0");
  const [Profileotp, setProfileOTP] = useState("");
  const Country_API = async () => {
    try {
      let responceRight = await API?.post(`GetCountryData`, {
        CountryId: "0",
      });
      console.log("GetCountryData-->", responceRight.data.data[0]);
      setCountries(responceRight.data.data[0]);
    } catch (error) {
      console.log("Error While calling Myteam API", error);
    }
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    //console.log("selectedOption", selectedOption);
    setPhonecode(selectedOption.phonecode);
    setCountryId(selectedOption.value);
    setCountryName(selectedOption.label);
  };

  const GetSponsorDetails = async (e) => {
    let { value } = e.target;
    const newValue = value.replace(/[^0-9]/gi, "").substring(0, 8);
    if (newValue === "") {
      setSponsorId("");
      setSponsorName("");
    } else {
      setSponsorId(newValue);
      let res = await API.post(`/check_sponsorid`, { uid: newValue });

      if (res.data.data.result === "Sponsor Id doesnot exists !!") {
        setSponsorName("Wrong sponser id");
      } else {
        // let { f_name } = res.data.data[0];
        setSponsorName(res.data.data.result);
      }
    }
  };

  const checkUserName = async (e) => {
    let { value } = e.target;

    const newValue = value.replace(/[^A-Z a-z]/g, "");

    if (newValue === "") {
      setf_name("");
    } else {
      setf_name(newValue);
    }
  };

  const checkEmailId = async (e) => {
    let { value } = e.target;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailId(value);
    if (!emailRegex.test(value)) {
      setEmailIdError("Please enter a valid email address.");
    } else {
      setEmailIdError("");
    }
  };

  const SendOtpEmailVerification_API = async () => {
    try {
      let res = await API.post("emailVerification", {
        sid: SponsorId,
        f_name: f_name,
        email: EmailId,
        psw: Password,
        mob: MobileNo,
        CountryId: CountryId,
      });

      res = res?.data?.data;
      //console.log("SendOtp_API", res);
      if (res === "Successfull") {
        setProfileOTPValid("1");
        toast.success("OTP Send Successfully !!");
      } else {
        setProfileOTPValid("0");
        toast.error(res);
      }
    } catch (e) {
      console.log("Somthing Error in SendOtp_API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
      }
    }
  };

  const Registration = async (e) => {
    // setspin  nerload(true);

    try {
      if (Password !== ConfirmPassword) {
        toast.error("Password Not Mathced!!!");
        return;
      }
      let resSponsor = await API.post(`/check_sponsorid`, { uid: SponsorId });

      if (resSponsor.data.data.result === "Sponsor Id doesnot exists !!") {
        toast.error("Wrong sponser id!!!");
        return;
      }

      let res = await API.post("/User_Registration", {
        sid: SponsorId,
        sponser_id: "",
        f_name: f_name,
        email: EmailId,
        psw: Password,
        mob: MobileNo,
        Bookie_Id: "0",
        RegBy: "User",
        countryName: CountryName,
        CountryId: CountryId,
        PhoneCode: PhoneCode,
        otp: Profileotp,
      });
      console.log("RegistrationResponse-->", res);

      if (res.data.data.result === "Successfully Registered") {
        sessionStorage.setItem("Name", f_name);
        sessionStorage.setItem("Password", Password);
        toast.success(`Successful`);
        let uid = res.data.data.uid_output;
        console.log("resuid", uid);
        history(`/welcomepage/${uid}`);

        // history(`/WelcomePage?userid=${uid}`);
      } else {
        toast.error(`${res.data.data.result}`);
        // setspinnerload(false);
      }
      // setspinnerload(false);
    } catch (error) {
      console.log("API ERROR", error);
    }
  };

  // const emailVerification = async (Id) => {
  //   try {
  //     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //     console.log("Email_check", getEmail);
  //     if (!emailRegex.test(getEmail)) {
  //       toast.error("Please enter a valid email address.");
  //     } else {
  //       setspinner(true);
  //       let res = await API.post("/emailVerification", {
  //         email: getEmail,
  //       });
  //       if (res.data.data === "Successfull") {
  //         Id === "Resend"
  //           ? toast.success(`OTP send Successfull on this ${getEmail}  `)
  //           : toast.success(`OTP Resend Successfull on this ${getEmail}  `);
  //       }
  //       setspinner(false);
  //     }

  //     //   console.log("res", res);
  //   } catch (error) {
  //     console.log(error);
  //     setspinner(false);
  //   }
  // };

  const ReferralAddress = async () => {
    try {
      let URL = window.location.href;
      let URL_ID = new URLSearchParams(window.location.search).get("userid");

      if (URL.includes("userid")) {
        // setcheckreffarl(true);
        setSponsorId(URL_ID);
        let res = await API.post(`/check_sponsorid`, { uid: URL_ID });

        if (res.data.data.result === "Sponsor Id doesnot exists !!") {
          setSponsorName("Wrong sponser id");
        } else {
          // let { f_name } = res.data.data[0];
          setSponsorName(res.data.data.result);
        }
      } else {
      }
    } catch (e) {
      console.log("Erroe Whille Referral Fuction Call", e);
    }
  };

  const hendleOTPProfile = (event) => {
    const newValue = event.replace(/[^0-9]/gi, "");
    setProfileOTP(newValue);
  };

  useEffect(() => {
    Country_API();
    ReferralAddress();
  }, []);

  return (
    <>
      <div className="background-img">
        <div className="d-flex justify-content-center align-items-center">
          <div className="card registration-card col-12 col-md-6 mt-5">
            <div className="d-flex justify-content-center mt-4">
              <img className="img-fluid img-div" src={logo1} alt="a" />
            </div>

            <div className="row mx-1">
              <div className="col-sm-6 ">
                <div className="col-12  mx-1">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Sponser ID
                  </label>
                  <input
                    type="text"
                    className=" registration-input p-1 px-2"
                    id="exampleInputEmail1"
                    placeholder="Enter Sponser ID"
                    aria-describedby="emailHelp"
                    value={SponsorId}
                    onChange={(e) => {
                      GetSponsorDetails(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="col-12 mx-1">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Sponsor Name
                  </label>
                  <input
                    type="text"
                    className=" registration-input p-1 px-2"
                    id="exampleInputEmail1"
                    placeholder="Sponsor Name"
                    aria-describedby="emailHelp"
                    readOnly="true"
                    value={SponsorName}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            <div className="row mx-1">
              <div className="col-sm-6">
                <div className="col-12 mx-1">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    User Name
                  </label>
                  <input
                    type="text"
                    className=" registration-input p-1 px-2"
                    id="exampleInputEmail1"
                    placeholder="Enter UserName"
                    aria-describedby="emailHelp"
                    maxLength={50}
                    value={f_name}
                    onChange={(e) => {
                      checkUserName(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-12 mx-1">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      E-mail ID
                    </label>
                    <input
                      type="text"
                      className=" registration-input p-1 px-2"
                      id="exampleInputEmail1"
                      placeholder="Enter Email ID"
                      aria-describedby="emailHelp"
                      maxLength={100}
                      onChange={(e) => {
                        checkEmailId(e);
                      }}
                      value={EmailId}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="row mb-2">
                  <div className="col-12 mx-1">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Country Name
                    </label>
                    <Select
                      name="country"
                      className="registration-input p-1 px-2"
                      id="countries"
                      value={country}
                      onChange={handleCountryChange}
                      options={countries.map((country) => ({
                        value: country.id,
                        label: country.nicename,
                        phonecode: country.phonecode,
                      }))}
                      placeholder="Select Country"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="row mx-0">
                  <div className="col-12 mx-1">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Mobile No.
                    </label>
                    <div className="inputgroup position-relative">
                      <input
                        className="inputmain position-absolute top-0 start-0"
                        type="text"
                        readOnly="true"
                        defaultValue={PhoneCode}
                        placeholder="+Code"
                      />
                      <input
                        type="text"
                        className="registration-input inputm p-1 px-2"
                        placeholder="Enter Mobile No"
                        maxLength={15}
                        onChange={(e) => {
                          setMobileNo(e.target.value);
                        }}
                        value={MobileNo}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mx-1">
              <div className="col-sm-6">
                <div className="col-12 mx-1">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Enter Password
                  </label>
                  <input
                    type="password"
                    className=" registration-input p-1 px-2"
                    placeholder="Enter your password"
                    id="exampleInputPassword1"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    value={Password}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="row mx-0">
                  <div className="col-12">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      className=" registration-input p-1 px-2"
                      placeholder="Confirm your password"
                      id="exampleInputPassword1"
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                      value={ConfirmPassword}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mx-1">
              {Profileotpvalid === "1" ? (
                <div className="col-sm-6">
                  <div className="row mx-0">
                    <div className="col-12">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        OTP
                      </label>
                      <input
                        type="text"
                        maxLength={8}
                        className=" registration-input p-1 px-2"
                        placeholder="OTP"
                        title="OTP is required"
                        id="exampleInputPassword1"
                        onChange={(e) => hendleOTPProfile(e.target.value)}
                        value={Profileotp}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox color="success" />}
                  checked={checkbox}
                  onChange={(e) => setcheckbox(e.target.checked)}
                  className="text-white"
                  required
                  label="I Agree Your Terms and Conditions"
                />
              </FormGroup>
            </div>
            <div className="d-flex justify-content-center">
              {Profileotpvalid === "1" ? (
                <button
                  type="button"
                  className="btn login-btn mt-2"
                  onClick={(e) => Registration(e)}
                >
                  Registration
                </button>
              ) : (
                <button
                  type="button"
                  className="btn login-btn mt-2"
                  onClick={() => SendOtpEmailVerification_API()}
                >
                  GET OTP
                </button>
              )}
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="bth d-flex justify-content-center mt-2">
                  Back to &nbsp;{" "}
                  <span>
                    <Link className="nav-link" to="/">
                      {" "}
                      Home
                    </Link>{" "}
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="bth d-flex justify-content-center mt-2">
                  Already have an Account &nbsp;{" "}
                  <span>
                    <Link className="nav-link" to="/login">
                      {" "}
                      Sign In
                    </Link>{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
