import React, { useEffect, useState } from "react";

import CashOut from "./CashOut";
import OddsTable2 from "./Table/OddsTable2";
import { fetchbetStackData } from "../../Redux/api/betStackAPI";
import { useDispatch, useSelector } from "react-redux";
import { saveTeams } from "../../Redux/Slices/SaveTeamsSlice";
import { io } from "socket.io-client";

const MatchOdds = ({
  data,
  handleModal,
  setmarketType,
  setbetType,
  setbetDetails,
  setRealtimeData,
  marketType,
  betType,
  realtimeData,
}) => {
  // console.log("data", data);
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");
  const dispatch = useDispatch();
  const { id, MarketID } = useSelector((state) => state.auth.currentMatch);
  const { oddStack } = useSelector((state) => state.betStack);
  const SOCKET_URL = "https://bluewing-socket.nakshtech.info/";

  const [dataValue, setDataValue] = useState("");

  const checkValid = () => {
    if (realtimeData && marketType === "Match Odds") {
      const filteredData = data[0].oddDatas?.filter(
        (item) => item.rname == dataValue?.team && item.sid == dataValue?.selId
      );
      
      if (filteredData[0].l1 === filteredData[0].b1) {
        setRealtimeData(false);
        setDataValue({});
      }

      if (betType === "back") {
        if (
          filteredData[0].sid == dataValue?.selId &&
          filteredData[0].status != "SUSPENDED" &&
          filteredData[0]?.b1 == dataValue?.valueOf &&
          filteredData[0]?.rname == dataValue?.team
        ) {
          return;
        } else {
          setRealtimeData(false);
          setDataValue({});
        }
      } else if (betType === "lay") {
        if (
          filteredData[0].sid == dataValue?.selId &&
          filteredData[0].status != "SUSPENDED" &&
          filteredData[0]?.l1 == dataValue?.valueOf &&
          filteredData[0]?.rname == dataValue?.team
        ) {
          return;
        } else {
          setRealtimeData(false);
          setDataValue({});
        }
      }
    }
  };

  useEffect(() => {
    checkValid();
  }, [realtimeData, dataValue, data]);

  useEffect(() => {
    dispatch(
      fetchbetStackData({
        userId: uid,
        mId: id,
        matchType: "Match Odds",
        token,
      })
    );
  }, [dispatch, id]);

  const [count, setCount] = useState(0);

  const SaveTeams_API = async (mname, team, sid) => {
    dispatch(saveTeams({ id, mname, team, token, sid }));
  };

  useEffect(() => {
    if (
      data &&
      data[0]?.oddDatas &&
      data[0]?.oddDatas.length > 0 &&
      count < 1
    ) {
      data[0]?.oddDatas?.forEach((runner) => {
        SaveTeams_API("Match Odds", runner.rname, runner.sid);
      });
      setCount(count + 1);
    }
  }, [data]);

  const [cashOut, setCashout] = useState(0);
  const [showCashout, setShowCashOut] = useState(false);

  // useEffect(() => {
  //   const cricketMatchesSocket = io(SOCKET_URL);
  //   cricketMatchesSocket.emit(
  //     "GetCashoutSocket",
  //     uid,
  //     MarketID,
  //     data[0]?.oddDatas[0].b1,
  //     data[0]?.oddDatas[0].l1,
  //     data[0]?.oddDatas[1].b1,
  //     data[0]?.oddDatas[1].l1
  //   );
  //   cricketMatchesSocket.on("GetCashoutSocket_FromAPI", (data) => {
  //     console.log("cashout==>", data);
  //     setCashout(data);
  //   });
  //   return () => {
  //     cricketMatchesSocket.disconnect();
  //   };
  // }, [
  //   uid,
  //   MarketID,
  //   data[0]?.oddDatas[0].b1,
  //   data[0]?.oddDatas[0].l1,
  //   data[0]?.oddDatas[1].b1,
  //   data[0]?.oddDatas[1].l1,
  // ]);

  // console.log("odds", data[0]?.oddDatas[0].b1);

  // const calculateLiability = () => {
  //   let liability =
  //     oddStack && oddStack.length > 0
  //       ? Number(oddStack[0]?.amount || 0) > 0 &&
  //         Number(oddStack[1]?.amount || 0) > 0
  //         ? "0"
  //         : Math.abs(
  //             Math.min(
  //               Number(oddStack[0].amount || 0),
  //               Number(oddStack[1].amount || 0)
  //             )
  //           )
  //       : 0;
  //   return liability;
  // };

  return (
    <div>
      {/* {data[0]?.oddDatas?.length > 0 && (
        <div className="liab_bars">
          <CashOut
            calculateLiability={calculateLiability}
            Cashout={cashOut}
            setShowCashOut={setShowCashOut}
          />
        </div>
      )} */}
      <div className="row  bg-dark ">
        <div className="Match-ods-h d-flex align-items-center">
          <p className="mx-2 mt-3 d-flex">
            MATCH ODDS <div className="green-live-dot ms-2 mt-2"></div>
          </p>
        </div>
        <div className="row back-lay-row  bg-dark ">
          <div className="col-7 "></div>
          <div className="col-5 d-flex">
            <button className="back ">Back</button>
            <button className="lay ms-1">Lay</button>
          </div>
        </div>
        <div className="betting-team-info">
          {data &&
            data.length > 0 &&
            data[0] &&
            (data[0]?.oddDatas?.length > 0 ? (
              data[0]?.oddDatas?.map((item, index) => (
                <OddsTable2
                  key={index}
                  data={item}
                  matchType="Match Odds"
                  mid={data[0].mid}
                  handleModal={handleModal}
                  setbetType={setbetType}
                  setmarketType={setmarketType}
                  setbetDetails={setbetDetails}
                  setRealtimeData={setRealtimeData}
                  setDataValue={setDataValue}
                  Teamexp={oddStack?.filter((ele) => ele.team == item?.rname)}
                />
              ))
            ) : (
              <div className="text-center py-2">No Bet !</div>
            ))}
          {data && data.length === 0 && (
            <div className="text-center py-2">No Bet !</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MatchOdds;
