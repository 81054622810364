import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserWithdrawlHistoryData } from "../../Redux/Slices/withdrawHistorySlice";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";

const WithdrawHistory = () => {
  const dispatch = useDispatch();
  const { withdrawHistory, isLoading, error } = useSelector(
    (state) => state.withdrawHistory
  );

  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);

  const token = sessionStorage.getItem("token");
  // console.log("storedJwtToken ",storedJwtToken)
  const userId = sessionStorage.getItem("userData");
  // console.log("stored userdata ",userdata);

  useEffect(() => {
    dispatch(fetchUserWithdrawlHistoryData({ id: userId, token }));
  }, [dispatch, userId, token]);

  useEffect(() => {
    if (withdrawHistory) {
      console.log("Response data from  bethistory API:", withdrawHistory);
    }
  }, [withdrawHistory]);

  if (error) {
    console.log("withdrawHistory call is having error");
  }

  useEffect(() => {
    if (!isLoading && withdrawHistory.length > 0) {
      const arr = withdrawHistory.map((item, index) => ({
        sr: index + 1,
        uid: item.uid,
        // touserid: item.touserid,
        // wallet: item.wallet
        //   ? item.wallet.substring(0, 4) +
        //     "..." +
        //     item.wallet.substring(item.wallet.length - 4)
        //   : null,
        request_date: item?.request_date,
        Request_amount: item?.Request_amount,
        amountusd: item?.amountusd,
        AGCAmount: item?.AGCAmount,
        admincharge: item?.adminchargerate,
        amount: item?.amount,
        rate: `${item?.rate} %`,
        req_date: item?.rdate,
        Remark: item?.Remark,
        ss: item?.ss,
        txn: (
          <a
            className="link-danger"
            href={`https://bscscan.com/tx/${item?.txn}`}
            target="_blank"
            style={{ whiteSpace: "nowrap" }}
            rel="noreferrer"
          >
            View Txn
          </a>
        ),
        trans_date: item.tdate,
      }));
      setreferralApi(arr);
    }
  }, [isLoading, withdrawHistory]);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);
  var [matching_income, set_matching_income] = useState({
    cols: [
      { Header: "Sr.No", accessor: "sr" },
      { Header: "User ID", accessor: "uid" },
      { Header: "PTS Amount", accessor: "Request_amount" },
      //{ Header: "USDT Amount", accessor: "amountusd" },
      //{ Header: "AGC Amount", accessor: "AGCAmount" },
      { Header: "Type", accessor: "Remark" },
      { Header: "	Rate %", accessor: "rate" },
      { Header: "Deduction", accessor: "admincharge" },
      { Header: "Pay PTS Amount ", accessor: "amount" },
      { Header: "Pay USDT Amount ", accessor: "amountusd" },
      { Header: "	Requested Date", accessor: "req_date" },
      { Header: "	Status", accessor: "ss" },
      { Header: "TXN ", accessor: "txn" },
      { Header: "Paid Date & Time", accessor: "trans_date" },
    ],
  });

  return (
    <div className="col-lg-10 com-h">
      <div className="row">
        <div className="col-12 p-3">
          <div className="bet-h ">
            <h4 className="p-2 ms-2">Withdraw History</h4>
            {/* <div className="dates-div p-2 d-flex ">
              <div className="row">
                <div className="col-md-5 mt-1">
                  <div className="d-flex">
                    <div className="from d-flex align-items-center justify-content-center">
                      From:
                    </div>
                    <div className="">
                      <input
                        name="start_date"
                        id="from"
                        type="date"
                        autocomplete="off"
                        placeholder="Select date from"
                        className="date-input p-2 "
                        value="2024-02-10"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-5 mt-1">
                  <div className="d-flex ">
                    <div className="from d-flex align-items-center justify-content-center">
                      To:
                    </div>
                    <div className="">
                      <input
                        name="start_date"
                        id="from"
                        type="date"
                        autocomplete="off"
                        placeholder="Select date from"
                        className="date-input p-2 "
                        value="2024-02-10"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-2  mt-1">
                  <button className="btn btn-success ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-search"
                      viewBox="0 0 16 16">
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                    </svg>{" "}
                    Search
                  </button>
                </div>
              </div>
            </div> */}
            {/* <table className="table table-dark table-striped bd">
              <thead>
                <tr>
                  <th scope="col">No.</th>
                  <th scope="col">AMOUNT</th>
                  <th scope="col">DATE TIME</th>
                  <th scope="col">STATUS</th>
                </tr>
              </thead>
              <tbody>
                <tr>
      <th scope="row">1</th>
      <td>Match</td>
      <td>Otto</td>
      <td>@mdo</td>
    </tr>
    <tr>
      <th scope="row">2</th>
      <td>Jacob</td>
      <td>Thornton</td>
      <td>@fat</td>
    </tr>
    <tr>
      <th scope="row">3</th>
      <td colspan="2">Larry the Bird</td>
      <td>@twitter</td>
    </tr>
              </tbody>
            </table> */}

            <Table data={[...currentPost]} columns={matching_income.cols} />
            <Table_Buttons
              setcurrentPage={setcurrentPage}
              currentPage={currentPage}
              totalData={referralApi.length}
              listPerpage={listPerpage}
              handleRowsPerPageChange={setlistPerpage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawHistory;
