import axios from "axios";
import { API } from "../../API/API";

const updateTransPassAPI = axios.create();

export const updateTransPassword = async (data, token) => {
  try {
    const response = await API.post("/Update_passTransaction", data, {
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export default updateTransPassAPI;
