import React from "react";
import "./ChangePassword.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateTransPasswordData } from "../../Redux/Slices/updateTransPassSlice";
import { toast } from "react-toastify";
import { API } from "../../API/API";
import { logoutUser } from "../../Redux/Slices/authSlice";

const ChangeTransactionPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newCPassword, setNewCPassword] = useState("");
  const { isLoading, error } = useSelector((state) => state.updatePass);
  const token = sessionStorage.getItem("token");
  const [Profileotpvalid, setProfileOTPValid] = useState("0");
  const [Profileotp, setProfileOTP] = useState("");
  // const uid = '727681';
  const uid = sessionStorage.getItem("userData");

  // Replace with the user's UID

  const SendOtpChangePassword_API = async () => {
    try {
      let res = await API.post(
        "updateChangeTransPasswordOtp",
        {
          uid: uid,
          oldPassword: oldPassword,
          newPassword: newPassword,
        },
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      res = res?.data?.data;
      //console.log("SendOtp_API", res);
      if (res === "Successfull") {
        setProfileOTPValid("1");
        toast.success("OTP Send Successfully !!");
      } else {
        setProfileOTPValid("0");
        toast.error(res);
      }
    } catch (e) {
      console.log("Somthing Error in SendOtp_API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  const hendleOTPProfile = (event) => {
    const newValue = event.replace(/[^0-9]/gi, "");
    setProfileOTP(newValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let res = await dispatch(
      updateTransPasswordData({
        uid,
        oldPassword,
        newPassword,
        token,
        Profileotp,
      })
    );

    console.log("Submit_res", res.payload.result);

    if (res.payload.result === "Update Successfull") {
      toast.success("Transaction password changed successfully");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      toast.info(res.payload.result);
    }
  };
  if (isLoading) {
    // return <div>Loading...</div>;
  }

  // if (isSuccess) {
  //   console.log("password changed successfully", isSuccess);
  //   toast.success("password changed successfully");
  // }

  // if (error) {
  //   // return <div>Error: {error.message}</div>;
  // }
  return (
    <div className="col-lg-10">
      <div className="row">
        <div className="col-12 p-1 px-2">
          <div className="funds-transfer-main-div ">
            <div className="F-h p-2 pb-0">
              <p className="ms-3">Change Transaction Password</p>
            </div>

            <div className="form-area d-flex justify-content-center align-items-center">
              <div className="card cp-card mt-4 py-2 col-lg-8">
                <div className="px-4">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputPassword1">
                        Current Password*
                      </label>
                      <input
                        type="password"
                        className="form-control mt-2"
                        id="exampleInputPassword1"
                        placeholder="Current Password"
                        onChange={(e) => {
                          setOldPassword(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputPassword1">
                        New Password
                      </label>
                      <input
                        type="password"
                        className="form-control mt-2"
                        id="exampleInputPassword1"
                        placeholder="New Password"
                        onChange={(e) => {
                          setNewPassword(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputPassword1">
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        className="form-control mt-2"
                        id="exampleInputPassword1"
                        placeholder=" Confirm Password"
                        onChange={(e) => {
                          setNewCPassword(e.target.value);
                        }}
                      />
                    </div>

                    {Profileotpvalid === "1" ? (
                      <>
                        <div className="form-group">
                          <label>OTP</label>
                          <input
                            type="text"
                            name="otp"
                            maxLength={8}
                            className="form-control mt-2"
                            required=""
                            title="OTP is required"
                            placeholder="OTP"
                            onChange={(e) => hendleOTPProfile(e.target.value)}
                            value={Profileotp}
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {Profileotpvalid === "1" ? (
                      <button type="submit" className="btn transfer-btn mt-4">
                        Update Password
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn transfer-btn mt-4"
                        onClick={() => SendOtpChangePassword_API()}
                      >
                        GET OTP
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeTransactionPassword;
